<template>
  <va-tree-category
    :label="root.name"
    v-if="root.children.length > 0"
  >
    <hierarchy-tree
      v-for="child in root.children"
      :root="child"
      :key="child.id"
    />
  </va-tree-category>
  <va-tree-node v-else>{{ root.name }}</va-tree-node>
</template>

<script>
const HierarchyTree = () => import(/* webpackPrefetch: true */ './Hierarchy.vue')

export default {
  name: 'hierarchy-tree',
  components: { HierarchyTree },
  props: {
    root: {
      type: Object,
      required: true,
    },
  },
}
</script>
